import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetSocietyInsightsFilteringRequestPayload } from '@redux/types/insights/insightsTypes';
import {
  GetSocietyAcquisitionInsightsSuccess,
  SocietyAcquisitionInsightsState,
} from '@redux/types/insights/society-acquisition/societyAcquisitionInsightsTypes';

const storeName = 'societyAcquisitionInsights';

const initialState: SocietyAcquisitionInsightsState = {
  error: null,
  loading: false,
  reports: [],
  segments: [],
  societiesInvalid: false,
};

const societyAcquisitionInsightsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setListLoading(state: SocietyAcquisitionInsightsState, action: PayloadAction<boolean>) {
      state.error = null;
      state.loading = action.payload;
      state.reports = [];
      state.segments = [];
      state.societiesInvalid = false;
    },
    setListSocietiesInvalid(state: SocietyAcquisitionInsightsState, action: PayloadAction<boolean>) {
      state.societiesInvalid = action.payload;
    },
    getListSuccess(
      state: SocietyAcquisitionInsightsState,
      action: PayloadAction<GetSocietyAcquisitionInsightsSuccess>
    ) {
      state.loading = false;
      state.reports = action.payload.data;
      state.segments = action.payload.meta?.segments || [];
    },
    getListFailure(state: SocietyAcquisitionInsightsState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const societyAcquisitionInsightsActions = {
  setListLoading: societyAcquisitionInsightsSlice.actions.setListLoading,
  setListSocietiesInvalid: societyAcquisitionInsightsSlice.actions.setListSocietiesInvalid,
  getListSuccess: societyAcquisitionInsightsSlice.actions.getListSuccess,
  getListFailure: societyAcquisitionInsightsSlice.actions.getListFailure,
  getListRequest: createAction<GetSocietyInsightsFilteringRequestPayload>(`${storeName}/getListRequest`),
};

export const societyAcquisitionInsightsReducer = societyAcquisitionInsightsSlice.reducer;
